// Skills.js
import React from 'react';
import '../css/Skills.css'; // Make sure to import the CSS file for styling

const Skills = ({ skills }) => {
    return (
        <div className="skills-section">
            {Object.entries(skills).map(([category, skills]) => (
                <div key={category} className="skills-category">
                    <h3>{category}</h3>
                    <div className="skills-container">
                        {skills.map((skill, index) => (
                            <div key={index} className="skill-item">{skill}</div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Skills;
